<template>
  <div class="container">
    <div class="teacher-box">
      <div class="info">
        <div class="title">
          <div class="name">{{teacherInfo.name}}</div>
          <div class="college">
            <div class="txt" v-for="txt in teacherInfo.positionalTitles">{{txt}}</div>
            <!-- <div class="txt">{{teacherInfo.positionalTitles}}</div> -->
            <!-- <div class="post">{{teacherInfo.name}}</div> -->
          </div>
        </div>
        <div class="intro">{{teacherInfo.description}}</div>
      </div>
      <img class="teacher-img" :src="teacherInfo.image" alt="">
    </div>
    <div class="intro-box">
      <div class="title">详细介绍</div>
      <div class="info" v-html="teacherInfo.content"></div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      teacherId:this.$route.query.id,
      teacherInfo:{}
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.$http.get('/api/web/teacher/getDetails?teacherId='+this.teacherId)
        .then((res) => {
          this.teacherInfo = res.data.teacherInfo
        })
    },
  },
};
</script>

<style lang='less' scoped>
.container{
  min-height: calc(100vh - 410px);
  padding: 40px 0;
  background: #f7f7f7;
  .teacher-box{
    display: flex;
    width: 1300px;
    margin: 0 auto;
    background: url(../../../assets/images/teacher-bg.png) no-repeat right top;
    background-size:auto 100% ;
    background-color: #fff;
    border-radius: 20px;
    padding: 30px 70px;
    margin-bottom: 30px;
    overflow: hidden;
    .teacher-img{
      width: 267px;
      height: 365px;
      margin-right: 50px;
      margin-left: 60px;
      border-radius: 15px;
    }
    .info{
      display: flex;
      flex-direction: column;
      width: 100%;
      .title{
        padding-top: 20px;
        border-bottom: 1px solid #efefef;
        .name{
          font-weight: 700;
          font-size: 28px;
          line-height: 1;
          margin-bottom: 30px;
          color: #333;
          text-align: left;
        }
        .college{
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          color: #666;
          font-size: 16px;
          padding-bottom: 30px;
          .txt{
            font-weight: 700;
            margin-right: 15px;
            text-align: left;
            line-height: 1.5;
          }
        }
      }
      .intro{
        font-size: 14px;
        color: #999;
        line-height: 2;
        padding-top: 18px;
        text-align: left;
      }
    }
  }
  .intro-box{
    display: flex;
    flex-direction: column;
    width: 1300px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 20px;
    .title{
      text-align: left;
      padding: 30px 60px;
      font-size: 16px;
      font-weight: 700;
      font-family: '微软雅黑','Microsoft YaHei';
      border-bottom: 1px solid #efefef;
    }
    .info{
      text-align: left;
      padding: 30px 60px;
      color: #999;
      font-size: 14px;
      /deep/p{
        margin: 0;
        // line-height: 2;
      }
    }
  }
}
</style>
